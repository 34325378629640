<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="8">
        <settings-breadcrumbs v-model="project" />
      </v-col>
      <v-col class="text-right">
        <v-btn color="success" class="mr-2" @click="createEditShow()">
          <v-icon>mdi-plus</v-icon>
          New Plugin Instance
        </v-btn>
      </v-col>
    </v-row>
    <!-- Search Bar -->
    <v-text-field
      class="mb-1"
      v-model="q"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      clearable
    />

    <!-- Plugin instances shown as cards -->
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3" v-for="item in items" :key="item.id">
        <v-card outlined class="ma-2">
          <v-card-title>
            <v-icon large :color="getColorForPlugin(item.plugin.type)" class="mr-2">
              {{ getIconForPlugin(item.plugin.type) }}
            </v-icon>
            {{ item.plugin.title }}
          </v-card-title>
          <v-card-text>
            <div>
              <v-chip color="success" v-if="item.enabled">
                <v-icon class="mr-1">mdi-lightbulb-on-outline</v-icon>
                Enabled
              </v-chip>
              <v-chip color="error" v-if="!item.enabled">
                <v-icon class="mr-1">mdi-lightbulb-off-outline</v-icon>
                Disabled
              </v-chip>
            </div>
            <div>
              <br>
              <v-icon small class="mr-1">mdi-axis-arrow-info</v-icon>
              Description:<br>
              {{ item.plugin.description }}
            </div>
            <div>
              <br>
              Project:
              <project-card :project="item.project" />
            </div>
            <div class="mt-2">
              <v-icon small class="mr-1">mdi-clipboard-text-outline</v-icon>
              Slug:<br>
              <v-chip class="mr-2">
                <v-icon color="info" class="mr-1">mdi-clipboard-text-outline</v-icon>
                {{ item.plugin.slug }}
              </v-chip>
            </div>
            <div>
              <br>
              <v-icon small class="mr-1">mdi-format-superscript</v-icon>
              Type:<br>
              <v-chip class="mr-2">
                <v-icon color="teal lighten-1" class="mr-1">mdi-format-superscript</v-icon>
                {{ item.plugin.type }}
              </v-chip>
            </div>

            <!-- Google Service Account -->
            <div v-if="item.plugin.slug.startsWith('google-gmail')">

              <v-chip class="mt-3" color="info" text-color="white" @click="googleDialog = true" :disabled="item.enabled"
                >
                <v-icon class="mr-1">mdi-book-open-page-variant</v-icon>
                Installation Instructions</v-chip
              >
              <v-dialog v-model="googleDialog" max-width="600px">
                <v-card>
                  <v-card-title
                    >Installation Instructions for G Suite (Estimated Time: 5 minutes)</v-card-title
                  >
                  <v-card-text>
                    <div>
                      1. Navigate to the Google Cloud Platform (GCP)
                      <a href="https://console.cloud.google.com/">console</a>. Create a new GCP
                      Project for UnStruct's integration.
                    </div>
                    <div>
                      2. Create a new service account (APIs & Services > Credentials > Create
                      Credentials > Service Account). Download the JSON key.
                    </div>
                    <div>
                      3. Generate a Developer API key (APIs & Services > Credentials > Create
                      Credentials > API Key).
                    </div>
                    <div>
                      4. Enable required APIs (APIs & Services > Library) - Google Drive API, Google
                      Drive Activity API, Google People API, etc.
                    </div>
                    <div>
                      5. Set up the OAuth application (APIs & Services > OAuth Consent Screen) and
                      specify the provided scopes.
                    </div>
                    <div>
                      6. Go to G Suite Admin
                      <a href="https://admin.google.com/ac/owl/domainwidedelegation"
                        >Domain-wide Delegation</a
                      >
                      page (Security > API Controls > Domain-wide Delegation). Add a new API client.
                    </div>
                    <div>7. Enter the Client ID and OAuth scopes.</div>
                    <div>
                      Note: If not using Google Meet, omit the
                      `https://www.googleapis.com/auth/calendar` scope.
                    </div>
                    <div>
                      8. Connect to unstruct.ai and finalize integration using the saved JSON key and API
                      key.
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="googleDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <!-- Slack Ouath-->
            <div v-if="item.plugin.slug === 'slack-conversation'">
              <br>
              <a :href="slackOAuthLink">
                <img alt="Add to Slack" height="40" width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    :srcset="`https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x`" />
              </a>
            </div>
            <!-- Pagerduty Ouath-->
            <div v-if="item.plugin.slug === 'pagerduty-oncall'">
              <v-chip color="info" text-color="white" @click="pagerDutyDialog = true" class="mt-3" :disabled="item.enabled"
                >
                <v-icon class="mr-1">mdi-book-open-page-variant</v-icon>
                Installation Instructions
                </v-chip
              >
              <v-dialog v-model="pagerDutyDialog" max-width="600px">
                <v-card>
                  <v-card-title
                    >Installation Instructions for PagerDuty (Estimated Time: 5 minutes)</v-card-title
                  >
                  <v-card-text>
                    <div>
                      1. Navigate to the PagerDuty API Keys
                      <a href="https://pagerduty.com/api_keys">API Keys</a> page. Create a
                      new API key for UnStruct's integration.
                    </div>
                    <div>
                      2. Connect to unstruct.ai and finalize integration using the saved API key.
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="blue darken-1" text @click="pagerDutyDialog = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-card-text>

          <v-card-actions class="d-flex justify-end">
            <v-btn class="mr-3" color="secondary" :disabled="item.enabled" @click="createEditShow(item)">
              Enable
            </v-btn>
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="createEditShow(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-book-edit-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>View / Edit</v-list-item-title>
                </v-list-item>
                <v-list-item @click="removeShow(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import SettingsBreadcrumbs from "@/components/SettingsBreadcrumbs.vue"
import DeleteDialog from "@/plugin/DeleteDialog.vue"
import NewEditSheet from "@/plugin/NewEditSheet.vue"
import ProjectCard from "@/project/ProjectCard.vue"

export default {
  name: "PluginTable",

  components: {
    NewEditSheet,
    DeleteDialog,
    SettingsBreadcrumbs,
    ProjectCard
  },
  data() {
    return {
      headers: [
        { text: "Title", value: "plugin.title", sortable: true },
        { text: "Type", value: "plugin.type", sortable: true },
        { text: "Slug", value: "plugin.slug", sortable: true },
        { text: "Enabled", value: "enabled", sortable: true },
        { text: "Required", value: "plugin.required", sortable: true },
        { text: "Multiple Allowed", value: "plugin.multiple", sortable: true },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
      dialog: false,
      googleDialog: false,
      pagerDutyDialog: false,
    }
  },

  computed: {
    ...mapFields("plugin", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.options.filters.project",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
    ...mapFields("route", ["query"]),
    slackOAuthLink() {
      const baseHref = "https://slack.com/oauth/v2/authorize?";
      let clientId = "client_id=1058428797445.6680803679008"; // default prod for scale
      const scope = "scope=app_mentions:read,bookmarks:write,channels:history,channels:manage,channels:read,chat:write,commands,emoji:read,groups:history,groups:read,groups:write,im:history,im:read,im:write,mpim:read,mpim:write,pins:write,reactions:read,reactions:write,team:read,users.profile:read,users:read,users:read.email,users:write";
      const userScope = "user_scope=channels:read,groups:history,groups:read";

      // Dynamically change client_id based on hostname or other conditions
      if (window.location.hostname === "incident.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567";
      } else if (window.location.hostname === "ecsp-stage.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567";
      } else if (window.location.hostname === "ecsp-dev.unstructai.com") {
        clientId = "client_id=1058428797445.6339616666567";
      } else if (window.location.hostname === "ecsp.unstruct.ai") {
        clientId = "client_id=1058428797445.6339616666567";
      }
      return `${baseHref}${clientId}&${scope}&${userScope}`;
    }
  },

  created() {
    this.project = [{ name: this.query.project }]

    this.getAllInstances()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAllInstances()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending, vm.project],
      () => {
        this.page = 1
        this.$router.push({ query: { project: this.project[0].name } })
        this.getAllInstances()
      }
    )
  },

  methods: {
    ...mapActions("plugin", ["getAllInstances", "createEditShow", "removeShow", "getAll"]),
    getColorForPlugin(type) {
      switch (type) {
        case "conference":
          return "blue"
        case "conversation":
          return "success"
        case "ticket":
          return "error"
        case "email":
          return "deep-purple"
        case "signal-consumer":
          return "pink"
        case "oncall":
          return "yellow darken-2"
        case "statuspage":
          return "warning"
        case "participant":
          return "light-blue"
        case "participant-group":
          return "cyan"
        case "auth-provider":
          return "lime"
        case "contact":
          return "warning"
        case "metric":
          return "deep-orange"
        case "artificial-intelligence":
          return "pink darken-2"
        case "task":
          return "teal"
        case "storage":
          return "brown"
        case "document":
          return "grey darken-1"
        case "monitor":
          return "indigo"
        case "workflow":
          return "light-green"
        case "auth-mfa":
          return "purple"
        default:
          return "grey"
      }
    },
    getIconForPlugin(type) {
      switch (type) {
        case "conference":
          return "mdi-video-wireless-outline"
        case "conversation":
          return "mdi-chat"
        case "ticket":
          return "mdi-ticket"
        case "email":
          return "mdi-email"
        case "signal-consumer":
          return "mdi-rss"
        case "oncall":
          return "mdi-phone-in-talk"
        case "statuspage":
          return "mdi-list-status"
        case "participant":
          return "mdi-account"
        case "participant-group":
          return "mdi-account-group"
        case "auth-provider":
          return "mdi-key"
        case "contact":
          return "mdi-card-account-phone"
        case "metric":
          return "mdi-chart-bar"
        case "artificial-intelligence":
          return "mdi-creation"
        case "task":
          return "mdi-format-list-checks"
        case "storage":
          return "mdi-cloud-outline"
        case "document":
          return "mdi-file-document"
        case "monitor":
          return "mdi-desktop-mac-dashboard"
        case "workflow":
          return "mdi-cogs"
        case "auth-mfa":
          return "mdi-shield-lock"
        default:
          return "mdi-help-box"
      }
    },
  },
}
</script>

<style scoped>
.slack-install-link {
  display: inline-block;
  padding: 10px 15px;
  margin: 5px;
  background-color: #007bff;
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.button-link {
    display: inline-block;
    padding: 10px 15px;
    margin: 5px;
    background-color: #007bff;
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.button-link:hover {
    background-color: #0056b3;
}

</style>
