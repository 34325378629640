<!--
.. Mofified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-navigation-drawer v-model="showCreateEdit" app clipped right width="700">
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title v-if="id" class="title"> Edit </v-list-item-title>
          <v-list-item-title v-else class="title"> New </v-list-item-title>
          <v-list-item-subtitle>Plugin Instance</v-list-item-subtitle>
        </v-list-item-content>
        <v-btn icon color="info" :loading="loading" :disabled="!valid" @click="save()">
          <v-icon>save</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="closeCreateEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-list-item>
    </template>
    <v-card flat>
      <v-card-text>
        <v-form v-model="valid">
          <plugin-combobox v-if="!id" label="Plugin" v-model="plugin" />
          <v-checkbox
            v-model="enabled"
            hint="Each plugin type can only ever have one enabled plugin. Existing enabled plugins will be de-activated."
            label="Enabled"
          />
          <v-btn
            @click="toggleJsonInputMethod"
            v-if="isGooglePlugin"
            style="background-color: #4b5bd2; color: #ffffff"
            class="mb-3"
          >
            {{ showJsonInput ? "Use Form Instead" : "Use JSON Input Instead" }}
          </v-btn>

          <v-textarea
            v-if="showJsonInput && isGooglePlugin"
            v-model="jsonCredentials"
            label="Paste JSON Credentials"
            hint="Paste your Google service account JSON credentials here."
          ></v-textarea>

          <v-btn
            @click="parseJson"
            v-if="showJsonInput && isGooglePlugin"
            style="background-color: #4b5bd2; color: #ffffff"
            class="mb-3"
          >
            Parse JSON
          </v-btn>
          <v-jsf
            v-if="!plugin"
            v-model="configuration"
            :schema="configuration_schema"
            :options="options"
          />
          <v-jsf
            v-else
            v-model="configuration"
            :schema="plugin.configuration_schema"
            :options="options"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import VJsf from "@koumoul/vjsf"
import "@koumoul/vjsf/dist/main.css"
import { mapFields } from "vuex-map-fields"
import { mapActions, mapMutations } from "vuex"

import PluginCombobox from "@/plugin/PluginCombobox.vue"

export default {
  name: "PluginEditSheet",

  components: {
    VJsf,
    PluginCombobox,
  },

  data() {
    return {
      valid: false,
      options: {
        initialValidation: "all",
      },
      showJsonInput: false,
      jsonCredentials: "",
      configurationSchema: {},
      keyMapping: {
        service_account_project_id: "project_id",
        service_account_private_key_id: "private_key_id",
        service_account_private_key: "private_key",
        service_account_client_email: "client_email",
        service_account_client_id: "client_id",
      },
    }
  },

  computed: {
    ...mapFields("plugin", [
      "selected.id",
      "selected.project",
      "selected.enabled",
      "selected.configuration",
      "selected.configuration_schema",
      "selected.loading",
      "selected.plugin",
      "dialogs.showCreateEdit",
    ]),
    isGooglePlugin() {
      return this.plugin && this.plugin.slug.startsWith("google")
    },
    ...mapFields("route", ["query"]),
  },

  methods: {
    ...mapActions("plugin", ["save", "closeCreateEdit", "setInitialConfiguration"]),
    ...mapMutations("plugin", ["addConfigurationItem", "removeConfigurationItem"]),
    toggleJsonInputMethod() {
      this.showJsonInput = !this.showJsonInput
    },
    parseJson(jsonString) {
      try {
        const userConfig = JSON.parse(this.jsonCredentials)
        this.mapJsonToFormData(userConfig)
        this.showJsonInput = false // hide the json input
      } catch (e) {
        console.error("Parsing error:", e)
        alert(`Invalid JSON. Please check and try again. Error: ${e.message}`)
      }
    },
    mapJsonToFormData(userConfig) {
      const newConfiguration = { ...this.configuration }
      Object.keys(this.keyMapping).forEach((schemaKey) => {
        const userKey = this.keyMapping[schemaKey]
        if (userConfig[userKey] !== undefined) {
          this.$set(newConfiguration, schemaKey, userConfig[userKey])
        }
      })
      this.configuration = newConfiguration
    },
  },

  created() {
    if (this.query.project) {
      this.project = { name: this.query.project }
    }
    this.setInitialConfiguration(this.configuration_schema)
  },
}
</script>
